@import 'assets/styles/index.scss';
.redirect {
    margin-top: 10px;
    font-size: 12px;

    @include media('very-small') {
        text-align: center;
    }

    @include media('small') {
        text-align: left;
    }

    @include media('medium') {
        text-align: left;
        max-width: 360px;
    }

    @include media('large') {
        max-width: 410px;
    }
}
