@font-face {
    font-display: swap;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    src:
        local('Helvetica-Regular'),
        url('../fonts/Helvetica-Regular.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    src:
        local('Gilroy-ExtraBold'),
        url('../fonts/Gilroy-ExtraBold.ttf') format('truetype');
}
