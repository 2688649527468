$breakpoint--very-small: 360px;
$breakpoint--small: 600px;
$breakpoint--medium: 768px;
$breakpoint--large: 1024px;
$breakpoint--very-large: 1280px;
$breakpoint--wide: 1600px;

$color--white: #ffffff;
$color--alabaster: #f7f7f7;
$color--amour: #f6e6f2;
$color--silver-chalice: #aeaeae;
$color--paypoGreen50: #e7faf3;
$color--paypoGreen300: #7ee4bd;
$color--paypoGreen400: #54dba7;
$color--paypoGreen500: #2cd091;
$color--paypoGreen600: #22a271;
$color--paypoGreen700: #187350;
$color--grey-inactive: #b2b2b2;
$color--paypoGrey50: #f4f4f4;
$color--paypoGrey200: #cccccc;
$color--red-violet: #b91b85;
$color--cod-gray: #1a1a1a;
$color--grey: #868686;
$color--black: #000000;

$box-shadow--grey01:
    0px 2px 16px rgba(0, 0, 0, 0.02),
    0px 2px 7px rgba(0, 0, 0, 0.0402),
    0px 1px 3.5px rgba(0, 0, 0, 0.04),
    0px 1px 2.00345px rgba(0, 0, 0, 0.0398),
    0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 0px 0.5px rgba(0, 0, 0, 0.06);
