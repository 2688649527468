@import 'assets/styles/index.scss';

.main {
    @extend %container--desktop;

    display: flex;
    flex-direction: column-reverse;

    @include media('medium') {
        flex-direction: row;
    }

    &__container {
        @extend %container--mobile;

        padding-top: 30px;
        padding-bottom: 30px;

        @include media('medium') {
            padding: 50px 20px;
            background-color: $color--white;
        }

        @include media('large') {
            padding: 50px;
        }

        @include media('very-large') {
            padding: 50px 75px;
        }

        @include media('wide') {
            padding: 50px 240px 50px 80px;
            margin-left: 0;
        }
    }
}

.methods {
    &__returnShop {
        color: $color--silver-chalice;
        text-decoration: underline;
        font-weight: 800;
        font-family: 'Gilroy';

        @include media('small') {
            padding: 0;
        }
        @include media('medium') {
            box-sizing: border-box;
            width: 300px;
        }

        @include media('large') {
            width: 400px;
        }

        @include media('very-large') {
            width: 550px;
        }

        @include media('wide') {
            width: 600px;
        }
    }
}

.gateway {
    flex-shrink: 0;
    margin: 0;
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(4, 60px);
    grid-gap: 20px;

    @include media('small') {
        padding: 30px 0px;
        grid-template-columns: repeat(5, 80px);
    }

    @include media('medium') {
        box-sizing: border-box;
        width: 300px;
        padding: 50px 0px;
        grid-template-columns: repeat(4, 80px);
    }

    @include media('large') {
        width: 400px;
        padding: 50px 0;
    }

    @include media('very-large') {
        width: 550px;
        padding: 50px 0px;
        grid-template-columns: repeat(5, 80px);
    }

    @include media('wide') {
        width: 550px;
        padding: 50px 0px;
    }

    &__paymentMethod {
        width: 48px;
        text-align: center;
    }
    &__paymentName {
        color: $color--silver-chalice;
        width: 47px;
        font-size: 11px;
    }
    &__paymentButton {
        width: 53px;
        border: none;
        cursor: pointer;
        background-color: transparent;
        line-height: 12px;
    }
    &__paymentIcon {
        display: block;
        width: 48px;
        height: 48px;
        border: 2px solid $color--alabaster;
        border-radius: 10px;
        background-repeat: no-repeat;
        &:hover {
            border: 2px solid $color--paypoGreen500;
        }
        &--active {
            border: 2px solid $color--paypoGreen500;
        }
        &--disabled {
            opacity: 0.5;
        }
    }
}
.field {
    display: flex;
    max-width: 400px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: 0;
    text-align: left;

    &:first-of-type {
        margin-top: 30px;
    }
    @include media('medium') {
        margin-top: 2px;
    }

    &:last-of-type {
        margin-bottom: 20px;
    }

    &__checkbox {
        .checkbox {
            &__label {
                display: flex;
                align-items: flex-start;
                cursor: pointer;
            }

            &__input {
                display: none;

                &:checked ~ .checkbox__ballot-box {
                    outline: 1px solid $color--paypoGreen500;
                    background-color: $color--paypoGreen500;
                }
            }

            &__ballot-box {
                flex-shrink: 0;
                width: 12px;
                height: 12px;
                border: 2px solid $color--white;
                outline: 1px solid $color--silver-chalice;
                margin: 2px 10px 0 0;

                @include media('very-small') {
                    margin-right: 20px;
                }
            }

            &__toggle {
                @extend %font--accent;

                display: inline-block;
            }

            &__explanation {
                @extend %font--annotation;

                margin-top: 5px;

                @include media('very-small') {
                    text-align: justify;
                }
            }

            &__content {
                a {
                    color: $color--paypoGreen600;
                }
            }
        }

        &--error {
            @extend .field__checkbox;

            .checkbox {
                &__label {
                    color: $color--red-violet;
                }

                &__ballot-box {
                    outline-color: $color--red-violet;
                }
            }
        }
    }

    &__common {
        position: relative;

        .common {
            &__input {
                @extend %font--secondary;

                box-sizing: border-box;
                width: 100%;
                height: 50px;
                padding: 15px 61px 15px 20px;
                border: 1px solid $color--cod-gray;
                border-radius: 0.2rem;
                font-size: 1.2rem;
                text-transform: uppercase;

                @include media('medium') {
                    height: 52px;
                }

                @include media('large') {
                    font-size: 1rem;
                }

                &:focus ~ .common__label,
                &:not([value='']) ~ .common__label {
                    @extend %font--annotation;

                    top: -7px;
                    color: $color--cod-gray;
                    font-size: 1rem;

                    @include media('medium') {
                        top: -8px;
                    }
                }
            }

            &__icon {
                position: absolute;
                top: 15px;
                right: 21px;
                object-position: center;
                object-fit: scale-down;
                width: 20px;
                height: 20px;

                @include media('medium') {
                    top: 16px;
                }
            }

            &__label {
                position: absolute;
                top: 17px;
                left: 16px;
                padding-right: 5px;
                padding-left: 5px;
                cursor: text;
                background-color: $color--white;
                color: $color--silver-chalice;
                font-size: 1.2rem;
                transition:
                    top 0.2s,
                    color 0.2s,
                    font-size 0.2s;

                @include media('medium') {
                    top: 16px;
                }

                @include media('large') {
                    top: 17px;
                    font-size: 1rem;
                }
            }
        }

        &--error {
            @extend .field__common;

            .common {
                &__input {
                    border-color: $color--red-violet;

                    &:focus ~ .common__label,
                    &:not([value='']) ~ .common__label {
                        color: $color--red-violet;
                    }
                }

                &__label {
                    color: $color--amour;
                }
            }
        }
    }

    &__error {
        @extend %font--annotation;

        margin-top: 5px;
        color: $color--red-violet;
    }
}

.rodo {
    @extend %font--annotation;

    padding-top: 20px;
    border-top: 1px solid $color--alabaster;
    color: $color--silver-chalice;
    text-align: center;

    @include media('medium') {
        text-align: left !important;
    }

    &__statue {
        text-decoration: underline;
    }

    &__link:not(:first-of-type) {
        &::before {
            content: '|';
            padding-right: 5px;
            margin-left: 5px;
        }
    }
}
