@import 'assets/styles/index.scss';

#CybotCookiebotDialog {
    border-radius: 8px;
}

#CybotCookiebotDialog h2 {
    text-transform: inherit;
}

#CybotCookiebotDialog[data-template='overlay'].CybotCookiebotDialogActive {
    top: initial;
    bottom: 8px;
    transform: translate(-50%, 0);

    @media (min-width: 768px) {
        bottom: 48px;
        max-height: calc(100% - 96px);
    }
}

#CybotCookiebotDialog.CybotCookiebotDialogActive
    + #CybotCookiebotDialogBodyUnderlay {
    opacity: 0.4;
    pointer-events: auto;
}

#CybotCookiebotDialog #CybotCookiebotDialogHeader {
    display: none;
}

#CybotCookiebotDialogNav {
    border-bottom: rgba(0, 0, 0, 0);
    position: relative;
    width: 100%;
}

#CybotCookiebotDialogNav
    .CybotCookiebotDialogNavItem
    .CybotCookiebotDialogNavItemLink {
    padding: 4px 16px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
}

#CybotCookiebotDialog,
#CybotCookiebotDialogTabContent {
    font-weight: normal;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $color--paypoGrey200;
}

#CybotCookiebotDialog #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
    position: relative;
    height: 100%;
    max-height: 200px;
    min-height: 20vh;
    padding: 5px 0;

    @media (min-width: 768px) {
        max-height: 322px;
        min-height: fit-content;
    }
}

#CybotCookiebotDialogBody
    .CybotCookiebotScrollContainer
    #CybotCookiebotDialogBodyContent {
    padding: 8px;

    @media (min-width: 768px) {
        padding: 0 32px;
    }
}

#CybotCookiebotDialogBody
    .CybotCookiebotScrollContainer
    #CybotCookiebotDialogBodyContent
    #CybotCookiebotDialogBodyContentText {
    line-height: 1.3;
}

#CybotCookiebotDialog
    .CybotCookiebotDialogBodyLevelButtonSliderWrapper
    input:focus-visible
    + span,
#CybotCookiebotDialog :focus-visible {
    outline: rgba(0, 0, 0, 0);
}

#CybotCookiebotDialog
    .CybotCookiebotDialogContentWrapper
    #CybotCookiebotDialogFooter {
    padding: 8px 16px;
}

#CybotCookiebotDialogTabContent
    .CybotCookiebotDialogActive
    #CybotCookiebotDialogDetailBodyContentTextAbout {
    line-height: 20px;
}

#CybotCookiebotDialogDetailFooter,
#CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyLevelButtonCustomize
    .CybotCookiebotDialogArrow,
a#CybotCookiebotDialogPoweredbyCybot,
#CybotCookiebotDialogFooter #CybotCookiebotDialogPoweredByText {
    display: none;
}

#CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtons
    #CybotCookiebotDialogBodyButtonsWrapper {
    column-gap: 24px;
}

#CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtons
    #CybotCookiebotDialogBodyButtonsWrapper
    .CybotCookiebotDialogBodyButton {
    border: none;
    font-size: 14px;
    letter-spacing: -0.3px;
    width: initial;
    margin: 0;
}

#CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtons
    #CybotCookiebotDialogBodyButtonsWrapper
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    padding: 8px 28px;
    border-radius: 26px;
    border: 2px solid $color--paypoGreen500;
}

#CybotCookiebotDialog
    .CookieCard
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
    display: flex;
    align-items: flex-start;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContent .CybotCookiebotFader {
    width: calc(100% - 8px);
}
