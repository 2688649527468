@import 'assets/styles/index.scss';

.loader {
    position: relative;
    min-height: 100vh;
    background-color: $color--white;

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        transform: translate(-50%, -50%);
    }
}
