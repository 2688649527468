@import 'assets/styles/index.scss';

.footer-status {
    @extend %font--annotation;

    padding-top: 20px;
    border-top: 1px solid $color--alabaster;
    color: $color--silver-chalice;
    text-align: left;

    @include media('medium') {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    @include media('large') {
        max-width: 400px;
        margin-left: 0px;
        margin-right: 0;
    }

    @include media('very-large') {
        margin-left: 0px;
    }

    @include media('wide') {
        margin-left: 0px;
    }

    &__contact {
        @extend %font--secondary;

        color: $color--paypoGreen600;
    }

    &__links {
        display: flex;
        justify-content: flex-start;
        margin-top: 15px;
        margin-bottom: 15px;
        color: $color--cod-gray;
    }

    &__link-container:not(:first-of-type) {
        &::before {
            content: '|';
            padding-right: 5px;
            margin-left: 5px;
        }
    }

    &__link {
        color: $color--paypoGreen600;
        text-decoration: underline;
    }
}
