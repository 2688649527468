@import 'assets/styles/index.scss';

.checkout-header {
    flex-shrink: 0;

    @include media('medium') {
        box-sizing: border-box;
        width: 300px;
        padding: 50px 20px;
    }

    @include media('large') {
        width: 400px;
        padding: 50px;
    }

    @include media('very-large') {
        width: 550px;
        padding: 50px 75px;
    }

    @include media('wide') {
        width: 600px;
    }

    &__logos {
        @extend %container--mobile;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 10px;

        @include media('medium') {
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__paypo {
        object-position: left center;
        object-fit: scale-down;
        width: 120px;
        height: 50px;

        @include media('medium') {
            object-position: 50% 50%;
            object-fit: fill;
            width: 200px;
            height: 80px;
            margin-top: 0;
        }

        @include media('large') {
            width: 240px;
            margin-top: 0;
        }
    }

    &__shop {
        object-position: right top;
        object-fit: scale-down;
        width: 120px;
        height: 50px;
        margin-bottom: 5px;
        margin-left: auto;

        @include media('medium') {
            width: 180px;
            height: 80px;
            margin-top: 35px;
            margin-bottom: 10px;
            margin-left: 0;
        }
    }
}
