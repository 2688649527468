@import 'assets/styles/index.scss';

.tokenExpired {
    @extend %container--desktop;

    display: flex;
    flex-direction: column-reverse;

    @include media('medium') {
        flex-direction: row;
    }
}

.errorToken {
    background-color: $color--white;

    &--margin {
        display: block;
        @include media('medium') {
            margin-bottom: 450px;
        }
    }

    .main {
        &__container {
            @extend %container--mobile;

            padding-top: 30px;
            padding-bottom: 30px;

            @include media('medium') {
                padding: 50px 20px;
            }

            @include media('large') {
                padding: 50px 100px 50px 50px;
            }

            @include media('very-large') {
                padding: 50px 180px 50px 75px;
            }

            @include media('wide') {
                padding: 50px 300px 50px 80px;
            }
        }

        &__logo {
            width: 120px;
            margin-bottom: 30px;

            @include media('medium') {
                width: 200px;
            }

            @include media('large') {
                width: 240px;
            }
        }

        &__icon {
            width: 120px;
            margin-bottom: 30px;
        }

        &__description {
            margin-top: 40px;
            margin-bottom: 65px;
        }
    }
    &__returnPayment {
        @extend %font--secondary;

        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: $color--paypoGreen500;
        color: $color--black;
        font-size: 1.2rem;

        @include media('small') {
            width: 180px;
        }

        @include media('medium') {
            font-size: 1rem;
        }

        &:disabled {
            cursor: not-allowed;
            background-color: $color--silver-chalice;
            color: $color--white;
        }

        &__spinner {
            width: 30px;
            height: 30px;
            margin-left: 10px;
        }
    }
    &__menu {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
    }
    &__returnShop {
        @extend %font--secondary;
        color: $color--paypoGreen600;
        text-decoration: underline;
    }
    &__contact {
        @extend %font--primary;
        color: $color--silver-chalice;
        text-decoration: underline;
    }
}

.submit {
    @extend %font--secondary;

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 40px;
    background-color: $color--paypoGreen500;
    color: $color--black;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 20px 0;
    border-radius: 30px;
    transition:
        background-color 0.2s,
        color 0.2s ease-out;
    cursor: pointer;

    &:hover {
        background-color: $color--paypoGreen300;
        color: $color--paypoGreen700;
    }

    &:focus {
        border: 1px solid $color--white;
        background-color: $color--paypoGreen400;
        color: $color--black;
        outline: 2px solid $color--paypoGreen400;
    }

    &:active {
        border: 0;
        background-color: $color--paypoGreen600;
        color: $color--black;
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: $color--paypoGreen50;
        color: $color--grey-inactive;
    }

    &__spinner {
        width: 30px;
        height: 30px;
        margin-left: 10px;
    }
}
