@import 'assets/styles/index.scss';

.details {
    width: 100%;
    border-top: 1px solid $color--alabaster;
    border-bottom: 1px solid $color--alabaster;
    @include media('medium') {
        margin-bottom: 150px;
    }

    .details {
        &__detail {
            .detail {
                &__name {
                    min-width: 150px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                &__value {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    text-align: right;

                    @include media('small') {
                        padding-right: 60px;
                    }

                    &--cost {
                        @extend .detail__value;
                        @extend %font--headline;
                    }
                }
            }

            &:first-of-type .detail__name,
            &:first-of-type .detail__value {
                padding-top: 30px;
            }

            &:last-of-type .detail__name,
            &:last-of-type .detail__value {
                padding-bottom: 20px;
            }
        }
    }
}
